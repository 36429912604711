import { useEffect, useState } from "react";
import { Form } from '@kega/sps-elements';
import { useChangePassword } from '@kega/sps-core';

import { t } from "../../../../lib/translations";

import InfoMessage from "../../../../components/messages/InfoMessage";
import Input from "../../../../components/form/Input";
import Button from "../../../../components/buttons/Button";
import { Col, Row } from "../../../../components/grid";

import classes from './EditProfile.module.css';

const EditPassword = ({ onSuccess, handleEdit, editableField }) => {
    const [ noMatch, setNoMatch ] = useState(false);
    const [ wrongOldPass, setWrongOldPass ] = useState(false); 
    const { changePassword, error: resetError } = useChangePassword();

    const edit = editableField === "oldPassword"

    const resetStateAfterDelay = (stateSetter, delay = 10000) => {
        const timer = setTimeout(() => {
            stateSetter(false);
        }, delay);
        return () => clearTimeout(timer);
    };


    useEffect(() => {

        const hasPasswordMismatch = resetError?.data?.errors?.some((error) => 
            error.message.includes("PasswordMismatchException")
        );
        setWrongOldPass(!!hasPasswordMismatch);
        
    }, [resetError]);

    useEffect(() => {

        if (noMatch || wrongOldPass) {
            const clearTimeout = resetStateAfterDelay(
                noMatch ? setNoMatch : setWrongOldPass
            );
            return clearTimeout;
        }

    }, [noMatch, wrongOldPass]);

    const onSubmit = async ({ valid, values, resetForm }) => {
        const { oldpassword, password, password2 } = values;

        if (password !== password2) {
            setNoMatch(true);
            return;
        } else {
            setNoMatch(false);
        }
        
        if (valid) {
            const response = await changePassword({ newPassword: password, oldPassword: oldpassword });

            if(response.toString().length > 0) {
                return;
            } else {
                onSuccess();
                setWrongOldPass(false);
                handleEdit(null)
                resetForm();
            }
        } 
    }

    const InfoMessageContent = (
        <>
            <span>{ t('account.edit_password.password_min_digits') }<br/></span>
            <span>{ t('account.edit_password.password_min_capitals') }<br/></span>
            <span>{ t('account.edit_password.password_min_spec_characters') }<br/></span>
            <span>{ t('account.edit_password.password_min_length') }<br/></span>
        </>
    )

    return (
        <Form onSubmit={onSubmit} className={classes.password_form}>
            <Row className={classes.col}>
                <Col md={2} className={classes.col}>
                    <p>{t('account.profile.form.password')}</p>
                </Col>
                <Col md={6} className={classes.col}>
                    <Input 
                        className={classes.input}
                        name="oldpassword" 
                        type="password" 
                        required 
                        disabled={!edit}
                        placeholder={!edit ? '*********' : t('account.edit_password.oldpassword_label')}
                        error={wrongOldPass}
                        errorMessage={ t('account.edit_password.old_password_incorrect') }
                    />
                </Col>
                <Col md={2} className={classes.col}>
                    {edit ? (
                        <Button className={classes.save_password_btn} type="submit">{t('account.profile.form.save')}</Button>
                    ) : (
                        <p className={classes.button} onClick={() => handleEdit('oldPassword')}>{t('account.profile.form.edit')}</p>
                    )}
                </Col>
            </Row>
            {edit && <>
                <Row className={classes.col}>
                    <Col md={2} className={classes.col} />
                    <Col md={6} className={classes.col}>
                        <Input 
                            name="password" 
                            type="password" 
                            required 
                            placeholder={ t('account.edit_password.password1_label') }
                        />
                    </Col>
                </Row>
                <div className={classes.divider} />
                <Row className={classes.col}>
                    <Col md={2} className={classes.col} />
                    <Col md={6} className={classes.col}>
                        <Input 
                            name="password2" 
                            type="password" 
                            required 
                            placeholder={ t('account.edit_password.password2_label') } 
                            error={noMatch} 
                            errorMessage={(noMatch ? t('account.edit_password.error_password_not_equal') : t('form.error_not_empty'))}
                        />
                    </Col>
                </Row>
            </>}

            {edit && !wrongOldPass &&
            <InfoMessage className={classes.password_requirements}>
                <span>{ t('account.edit_password.password_requirements_text') }</span>
                {
                    resetError?.data.errors?.length > 0 ? 
                        resetError?.data.errors?.map(({ message }, index) => {
                            if (message === "field.password.min.one.number") {
                                return <span key={index}>{ t('account.edit_password.password_min_digits') }<br/></span>
                            }
                            else if (message === "field.password.min.one.uppercase") {
                                return <span key={index}>{ t('account.edit_password.password_min_capitals') }<br/></span>
                            }
                            else if (message === "field.password.min.one.special.char") {
                                return <span key={index}>{ t('account.edit_password.password_min_spec_characters') }<br/></span>
                            }
                            else if (message === "field.password.min.six.characters") {
                                return <span key={index}>{ t('account.edit_password.password_min_length') }<br/></span>
                            } else {
                                return InfoMessageContent 
                            }
                        })
                        : InfoMessageContent
                        
                }
            </InfoMessage> 
            }
        </Form>
    );
}

export default EditPassword;