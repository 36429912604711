import classNames from 'classnames';

import classes from './InfoMessage.module.css';

const InfoMessage = ({ children, className=null }) => {

    return (
        <span className={classNames(classes.root, className)}>
            { children }
        </span>
    );
}

export default InfoMessage;