import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@kega/sps-elements';

import { t } from '../../../../lib/translations';

import useTranslatePath from '../../../../hooks/useTranslatePath';
import useUpdateProfile from '../../../../hooks/customer/useUpdateProfile';
import useFetchCustomer from '../../../../hooks/customer/useFetchCustomer';

import Input from '../../../../components/form/Input';
import { Col, Row } from '../../../../components/grid';
import Select from '../../../../components/form/Select';
import EditPassword from './EditPassword';

import classes from './EditProfile.module.css';

const EditProfile = () => {
    const navigate = useNavigate();
    const { getPath } = useTranslatePath();
    const { updateProfile } = useUpdateProfile();
    const { getDetails } = useFetchCustomer();

    const { customer, loading } = useFetchCustomer();

    const [initialFields, setInitialFields] = useState([]);
    const [fields, setFields] = useState([]);
    const [editableField, setEditableField] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});

    useEffect(() => {
        if (customer) {
            const { firstName, lastName, email, titleCode, title, b2bUnits, addresses } = customer;

            const customerTitle = title && titleCode ? titleCode : '';
            const customerNumber = b2bUnits?.length ? b2bUnits[0].uid : '';
            const phoneNumber = addresses ? (addresses[0]?.phone || '') : '';
            const mobileNumber = addresses ? (addresses[0]?.cellphone || '') : '';

            const updatedFields = [
                { label: t('account.profile.form.salutation'), name: 'titleCode', value: customerTitle, actionType: 'edit' },
                { label: t('account.profile.form.name'), name: 'firstName', value: firstName, actionType: 'edit' },
                { label: t('account.profile.form.surname'), name: 'lastName', value: lastName, actionType: 'edit' },
                { label: t('account.profile.form.company_name'), name: 'companyName', value: 'HANOS SHOP', actionType: 'contact' },
                { label: t('account.profile.form.customer_number'), name: 'customerNumber', value: customerNumber, actionType: 'none' },
                { label: t('account.profile.form.email'), name: 'email', value: email, actionType: 'contact' },
                { label: t('account.profile.form.phone_number'), name: 'phoneNumber', value: phoneNumber, actionType: 'none' },
                { label: t('account.profile.form.mobile_number'), name: 'mobileNumber', value: mobileNumber, actionType: 'none' },
            ];

            setInitialFields(updatedFields);
            setFields(updatedFields);
        }
    }, [customer]);

    if (loading) { return <Loader />; }

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
            }, 10000);
    
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const handleEdit = (name) => {
        setEditableField(name);
    };

    const handleSave = async (fieldName, fieldValue, fieldLabel) => {
        setEditableField(null);

        const originalField = initialFields.find((field) => field.name === fieldName);

        if (originalField && originalField.value === fieldValue) {
            return;
        }

        try {
            await updateProfile({ [fieldName]: fieldValue });
            
            setSuccessMessage(t('account.profile.form.field_success', { field: fieldLabel }));
            getDetails();

        } catch (error) {
            setFields((prevFields) =>
                prevFields.map((field) =>
                    field.name === fieldName ? { ...field, value: originalField.value } : field
                )
            );

            setFieldErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: t('account.profile.form.error_message'),
            }));

            setTimeout(() => {
                setFieldErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: '',
                }));
            }, 10000);
        }
    };

    const handleChange = (name, value) => {
        setFields(fields.map((field) =>
            field.name === name ? { ...field, value: value } : field
        ));
    };
    
    return (
        <>
            <h1>{t('account.profile.form.title')}</h1>
            {fields.map((field, index) => (
                <Row key={index} className={classes.col}>
                    { 
                        field.actionType !== 'hidden' && (
                            <>
                                <Col md={2} className={classes.col}>
                                    <p>{field.label}</p>
                                </Col>
                                <Col md={6} className={classes.col}>
                                    {field.name === 'titleCode' ? 
                                        <Select
                                            name={field.name}
                                            value={field.value || ''}
                                            onChange={(e) => handleChange(field.name, e.target.value)}
                                            disabled={editableField !== field.name }
                                            className={classes.input}
                                            error={!!fieldErrors[field.name]}
                                            errorMessage={fieldErrors[field.name]}
                                        >
                                            {!field.value &&  <option value="" disabled />}
                                            <option value="mr">{t('account.profile.form.mr')}</option>
                                            <option value="mrs">{t('account.profile.form.mrs')}</option>
                                        </Select> : 
                                        <Input
                                            name={field.name}
                                            type={'text'}
                                            value={field.value}
                                            onChange={(e) => handleChange(field.name, e.target.value)}
                                            disabled={editableField !== field.name}
                                            className={classes.input}
                                            placeholder={field.placeholder}
                                            error={!!fieldErrors[field.name]}
                                            errorMessage={fieldErrors[field.name]}
                                        />
                                    }
                                </Col>
                                {field.actionType === 'edit' &&
                                    <Col md={2} className={classes.col}>
                                        {editableField === field.name ? (
                                            <p className={classes.button} onClick={() => handleSave(field.name, field.value, field.label)}>{t('account.profile.form.save')}</p>
                                        ) : (
                                            <p className={classes.button} onClick={() => handleEdit(field.name)}>{t('account.profile.form.edit')}</p>
                                        )}
                                    </Col>
                                }
                                {field.actionType === 'contact' &&
                                    <Col md={2} className={classes.col}>
                                        <p className={classes.button} onClick={() => navigate(getPath('/customer-service'))}>{t('account.profile.form.contact')}</p>
                                    </Col>
                                }
                            </>
                        )
                    }
                </Row>
            ))}
            <EditPassword 
                onSuccess={() => setSuccessMessage(t('account.profile.form.password_success'))} 
                handleEdit={handleEdit} 
                editableField={editableField}
            />
            {successMessage && <Col md={6} className={classes.success_message}>{successMessage}</Col>}
        </>
    );    
}

export default EditProfile