export default ({ client, tokens }) => {

    const api = {};

    api.updateProfileField = async (updatedData) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.patch('/users/current/', updatedData, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });

        });
    }

    api.updateAddress = async ({ addressId, updatedData }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.patch(`/hanosUsers/current/addresses/${addressId}`, updatedData, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });

    }

    api.removeAddress = async ({ addressId }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.delete(`/hanosUsers/current/addresses/${addressId}`, null, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });

        });
    }

    api.getOrders = async ({ dateFrom = '29/08/2022', dateTo, currentPage, orderCode = '', pageSize = '5', hanosOrderNumberOnly = true }) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            const payload = {
                dateFrom,
                dateTo,
                currentPage,
                orderCode,
                pageSize,
                hanosOrderNumberOnly
            }

            client.get('/hanosUsers/current/orders/search', payload, headers).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });

    }

    api.getDetails = async () => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }


            const payload = {
                fields: 'FULL'
            };

            client.get('/orgUsers/current', payload, headers).then((customer) => {
                resolve(customer);
            }).catch((error) => {
                reject(error);
            });
            
        });
    }

    return api;
};
