import { forwardRef } from 'react';

import { CheckRoundIcon } from '../../../components/icons';
import { t } from '../../../lib/translations';
import useTranslatePath from '../../../hooks/useTranslatePath';

import BuilderSectionLazy from '../../builder/BuilderSectionLazy';

import Pricing from './pricing/Pricing';
import AddToCart from './addtocart/AddToCart';
import TrustboxWidget from '../../../components/trustboxwidget/TrustboxWidget';

import classes from './ProductInfo.module.css';
import classNames from 'classnames';

const ProductInfo = ({ product }, ref) => {
    const { getPath } = useTranslatePath();

    const { name, Merk, packagingUnit, contentUnit, numbercontentunits, stock, price = null, variantOptions = [], nonfoodOrderUnitLabel, objectID = null, assortmentMessage = '', assortmentMinQuantity = 0, code, orderableAums } = product;
  
    const assortmentOrderQauntity = orderableAums?.[0]?.conversionFactor;
    const productName = product?.formattedName || product?.name;

    return (
        <div className={classNames(classes.root, (price?.oldPrice || price?.strikeThroughPrice) && classes.smaller_padding)}>
            <div className={classes.sticky}>
                {
                    (price !== null)
                    &&
                    <>
                        {price && <Pricing price={price} />}

                        <div className={classes.status}>
                            {
                                stock?.stockLevelStatus !== 'outOfStock'
                                    ?
                                    <div className={classNames(classes.stock, classes.in)}><CheckRoundIcon size={14} title="op-voorraad" /> {t('productdetails.info.in_stock')}</div>
                                    :
                                    <div className={classes.stock}>{t('productdetails.info.out_off_stock')}</div>
                            }

                            {
                                assortmentMessage !== ''
                                &&
                                <div className={classes.delivery}>{assortmentMessage}</div>
                            }
                        </div>

                        <hr className={classes.separator} />
                    </>
                }

                <div className={classes.unit}>
                    <div className={classes.row}>
                        <span className={classes.label} ref={ref}>{t('productdetails.info.packing')}</span> {packagingUnit} {numbercontentunits} {contentUnit}
                    </div>
                    {
                        assortmentMinQuantity > 0
                        &&
                        <div className={classes.row}>
                            <span className={classes.label}>{t('productdetails.info.qty_min')} </span>  {`${assortmentMinQuantity} ${assortmentMinQuantity === 1 ? t('productdetails.piece') : t('productdetails.pieces')}`}
                        </div>
                    }

                </div>

                <div className={classes.atc}>
                    <AddToCart
                        classNameLabel= {classes.atc_label}
                        product={product}
                        name={productName}
                        Merk={Merk}
                        unit={packagingUnit}
                        assortmentMinQuantity={assortmentMinQuantity}
                        objectID={objectID} 
                    />
                </div>

                <hr className={classes.separator} />

                <BuilderSectionLazy model="usp-pdp" url={getPath('/')} />

                <hr className={classes.separator} />

                <TrustboxWidget className={classes.widget} />

            </div>
        </div>
    )
}

export default forwardRef(ProductInfo);