import { Routes, Route, Navigate } from "react-router-dom";

import DefaultLayout from '../layouts/DefaultLayout';
import MinimalLayout from '../layouts/MinimalLayout';

import Home from '../pages/home/Home';
import Builder from "../pages/builder/Builder";
import ProductList from "../pages/productlist/ProductList";
import ProductDetails from "../pages/productdetails/ProductDetails";
import Search from "../pages/search/Search";
import Cart from '../pages/cart/Cart';
import Promotions from '../pages/promotions/Promotions';
import Account from '../pages/account/Account';
import Overview from '../pages/account/overview/Overview';
import Orders from '../pages/account/orders/Orders';
import Profile from '../pages/account/profile/Profile';
import EditProfile from '../pages/account/profile/edit/EditProfile';
import EditPassword from '../pages/account/profile/editpassword/EditPassword';
import Login from '../pages/account/login/Login';
import ForgotPassword from '../pages/account/forgotpassword/ForgotPassword';
import ResetPassword from '../pages/account/resetpassword/ResetPassword';
import Wishlist from '../pages/account/wishlist/Wishlist';
import Return from "../pages/account/return/Return";

import Checkout from '../pages/checkout/Checkout';
import Addresses from '../pages/checkout/addresses/Addresses';
import Payment from '../pages/checkout/payment/Payment';
import PaymentRedirect from '../pages/checkout/payment/redirect/Redirect';
import Shipment from '../pages/checkout/shipment/Shipment';
import Summary from '../pages/checkout/summary/Summary';
import Success from "../pages/checkout/success/Success";

import Register from '../pages/register/Register';
import CompanyCheck from '../pages/register/companycheck/CompanyCheck';
import SelectCompany from '../pages/register/selectcompany/SelectCompany';
import Details from "../pages/register/details/Details";
import Verification from '../pages/register/verification/Verification';
import RegisterSuccess from '../pages/register/registersuccess/RegisterSuccess';
import NewsletterSubscribed from '../pages/newsletter/NewsletterSubscribed';
import NewsletterUnSubscribed from '../pages/newsletter/NewsletterUnSubscribed';
//import Styleguide from "../pages/styleguide/Styleguide";

import useTranslatePath from '../hooks/useTranslatePath';
import useCheckLockedCart from "../hooks/useCheckLockedCart";

import RequireAuth from './RequireAuth';

const AppRoutes = () => {
    const { getRoute, getPath } = useTranslatePath();

    useCheckLockedCart();

    return (
        <Routes>
            <Route element={<DefaultLayout />} >

                <Route index element={<Home />} />

                {/*<Route path="/styleguide" element={<Styleguide />} />*/}

                <Route path={ getRoute('p/:product_key/*') } element={<ProductDetails />} />
                <Route path={ getRoute('c/:category_key/*') } element={<ProductList />} />
                
                <Route path={ getRoute('/account/login') } element={<Login />} />
                <Route path={ getRoute('/account/forgotpassword') } element={<ForgotPassword />} />
                <Route path={ getRoute('/account/resetpassword') } element={<ResetPassword />} />
                <Route path={ getRoute('/account') } element={<RequireAuth />} >
                    <Route element={<Account />}>
                        <Route index element={<Navigate replace to={ getRoute('/account/overview', { addStoreCode: true }) } />} />
                        <Route path={ getRoute('/account/overview') } element={<Overview />} />
                        <Route path={ getRoute('/account/orders') } element={<Orders />} />
                        <Route path={ getRoute('/account/profile') } element={<Profile />} />
                        <Route path={ getRoute('/account/return') } element={<Return />} />
                        <Route path={ getRoute('/account/wishlist') } element={<Wishlist />} />
                        <Route path={ getRoute('/account/profile/edit') } element={<EditProfile />} />
                        <Route path={ getRoute('/account/profile/editpassword') } element={<EditPassword />} />
                    </Route>
                </Route>

                <Route path={ getRoute('/cart') } element={<Cart />} />
                <Route path={ getRoute('/search') } element={<Search />} />
                <Route path={ getRoute('/promotions') } element={<Promotions />} />
                <Route path={ getRoute('/newsletter-subscribed') } element={<NewsletterSubscribed />} />
                <Route path={ getRoute('/newsletter-unsubscribed') } element={<NewsletterUnSubscribed />} />

                <Route path={ getRoute('/register') } element={<Register />}>
                    <Route index element={<Navigate replace to={ getRoute('/register/companycheck', { addStoreCode: true }) } />} />
                    <Route path={ getRoute('/register/companycheck') } element={<CompanyCheck />} />
                    <Route path={ getRoute('/register/selectcompany') } element={<SelectCompany />} />
                    <Route path={ getRoute('/register/details') } element={<Details />} />
                    <Route path={ getRoute('/register/verification') } element={<Verification />} />
                </Route>
                
                <Route path={ getRoute('/register/success') } element={<RegisterSuccess />} />
                
                <Route path=":builder_key/*" element={<Builder />} />

            </Route>

            <Route element={<MinimalLayout />}>
                <Route path={ getRoute('/checkout') } element={<RequireAuth returnPath={ getPath('/checkout') } />}>
                    <Route element={<Checkout />}>
                        <Route index element={<Navigate replace to={ getRoute('/checkout/addresses', { addStoreCode: true }) } />} />
                        <Route path={ getRoute('/checkout/addresses') } element={<Addresses />} />
                        <Route path={ getRoute('/checkout/shipment') } element={<Shipment />} />
                        <Route path={ getRoute('/checkout/summary') } element={<Summary />} />
                        <Route path={ getRoute('/checkout/payment') } element={<Payment />} />
                        <Route path={ getRoute('/checkout/payment/callback') } element={<PaymentRedirect />} />
                    </Route>
                    <Route path={ getRoute('/checkout/success') } element={<Success />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default AppRoutes;