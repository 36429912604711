import { useState, useEffect, useMemo } from 'react';

const useInViewport = (ref, enabled) => {
    const [isIntersecting, setIsIntersecting] = useState(true);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                // check intersecting only bellow the element
                if (entry.boundingClientRect.top > window.innerHeight) {
                    setIsIntersecting(true);
                } else {
                    setIsIntersecting(entry.isIntersecting);
                }
            }),
        []
    );

    useEffect(() => {
        if (enabled && ref.current !== null) {
            observer.observe(ref.current);
        }

        return () => {
            setIsIntersecting(true);
            observer.disconnect();
        };
    }, [ref]);

    return {
        isIntersecting
    };
};

export default useInViewport;
