import { Helmet } from "react-helmet-async";

import { t } from "../../../lib/translations";
import { default as ReturnModule } from '../../../modules/account/return/Return';

const Return = () => {
    return(
        <>
            <Helmet>
                <title>{t('account.navigation.return')}</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <ReturnModule />
        </>
    )
}

export default Return;