import { useEffect, useRef } from 'react';

import { CartItems } from '@kega/sps-cart';
import { useConfig, useCart, useClearCart } from '@kega/sps-core';

import BuilderSectionLazy from '../builder/BuilderSectionLazy';

import { t } from '../../lib/translations';

import LinkButton from '../../components/buttons/LinkButton';
import TrustboxWidget from '../../components/trustboxwidget/TrustboxWidget';

import PaymentMethods from '../../components/paymentmethods/PaymentMethods';
import { Col, Row, Container } from '../../components/grid';

import useMediaQuery from '../../hooks/useMediaQuery';
import useTranslatePath from '../../hooks/useTranslatePath';
import useTagManager from '../../hooks/useTagManager';
import useUserId from "../../hooks/useUserId";
import useItemCategories from '../../hooks/useItemCategories';

import CartItem from './item/CartItem';
import CartTotals from './totals/CartTotals';
import CartEmpty from './empty/CartEmpty';
import Coupon from './coupon/Coupon';
import FrequentlyBoughtTogether from './frequentlyboughttogether/FrequentlyBoughtTogether';

import classes from './Cart.module.css';

const Cart = ({ items, totals, coupons }) => {
    const { getPath } = useTranslatePath();
    const { ecommerceEvent } = useTagManager();
    const { match: desktop } = useMediaQuery('(min-width: 768px)');
    const { cartId, fetchCart } = useCart();

    const getUserId = useUserId();
    const clearCart = useClearCart();
    
    const tagManagerData = useRef({ items, totals }).current

    const config = useConfig();
    const categoryPath = items.map((item) => item.product.categoryPath);
    const itemCategories = useItemCategories(categoryPath);

    const freqBoughtObjectID = items.map((item) => item?.product?.objectID )
    
    useEffect(() => {

        const onFocus = async () => {
            const userId = getUserId();
            
            if (userId === 'anonymous') {
                const cart = await fetchCart(cartId, { userId: userId });
                if (cart?.hasOngoingPaymentSession) {
                    clearCart();
                }
            } else {
                fetchCart('current');
            }
        }

        window.addEventListener("focus", onFocus);

        return () => {
            window.removeEventListener("focus", onFocus);
        }
    }, [fetchCart])

    useEffect(() => {
        try {

            const { items, totals } = tagManagerData;

            ecommerceEvent('view_cart', {
                value: totals?.total?.value,
                currency: config.get('currency'),
                items: items.map(({ quantity = 0, product = {}, totalPrice, totalPriceWithoutCouponDiscount }) => {

                    return {
                        item_id: product?.code,
                        item_name: product?.formattedName || product?.name,
                        quantity: quantity,
                        item_brand: product?.Merk ?? product?.manufacturer,
                        price: totalPrice?.value,
                        totalPriceWithoutCouponDiscount: totalPriceWithoutCouponDiscount?.value,
                        item_category: itemCategories[0],
                        item_category2: itemCategories[1],
                        item_category3: itemCategories[2],
                        item_category4: itemCategories[3],
                        item_category5: itemCategories[4],
                    }

                })
            });
        } catch (error) {
            console.log(error)
        }

    }, [tagManagerData]);

    if (items.length === 0) { return <CartEmpty />; }

    
    return (
        <Container xl margins g-xs={2} g-md={3}>
            <Row>

                <Col md={12}>
                    <h1>{t('checkout.cart.title')}</h1>
                </Col>

                <Col md={7}>

                    {!desktop ? <LinkButton variant="secondary" className={classes.button} to={getPath('/checkout')} >{t('checkout.cart.continue_checkout')}</LinkButton> : null}

                    <CartItems
                        className={classes.items}
                        itemComponent={CartItem}
                    />

                </Col>

                <Col md={5}>

                    <div className={classes.totals}>
                        <h4>{t('checkout.cart.overview')}</h4>
                        <CartTotals
                            totals={totals}
                            coupons={coupons}
                        />
                    </div>

                    <Coupon coupons={coupons} />

                    <LinkButton variant="secondary" className={classes.button} to={getPath('/checkout')} >{t('checkout.cart.continue_checkout')}</LinkButton>

                    <div className={classes.payment}>
                        <PaymentMethods />
                    </div>

                    <div className={classes.usp}>
                        <BuilderSectionLazy model="usp-pdp" url={getPath('/')} />
                    </div>

                    <div className={classes.widget}>
                        <TrustboxWidget />
                    </div>
                </Col>
                <Col md={12}>
                    <div className={classes.freq_bought}>
                        <FrequentlyBoughtTogether code={freqBoughtObjectID} />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Cart;