import { useConfig } from '@kega/sps-core';
import { Price } from '@kega/sps-components';

import { t } from '../../../../lib/translations';

import classes from './Pricing.module.css';
import classNames from 'classnames';

const Pricing = ({ price: { value, valueWithVat = null, oldPrice = null, strikeThroughPrice = null, dynamic = [], promotion = false }, showValueWithVat = true, productInfoModal = false }) => {

    const config = useConfig();

    const currency = config.get('currency');
    const locale = config.get('locale');

    oldPrice = oldPrice || strikeThroughPrice;

    if (!value || typeof value !== 'number') {
        return null; 
    }

    return (
        <div className={classNames(classes.pricing, productInfoModal && classes.modal)}>
            <div className={classes.price}>
                {
                    oldPrice
                    &&
                    <div className={classes.price_from_wrapper}>
                        {
                            !promotion
                            &&
                            <span className={classes.price_from_label}>{ t('productdetails.info.msrp') }</span>
                        }
                        <Price price={oldPrice} currency={currency} locale={locale} currencySymbol={false} className={classNames(classes.price_from, productInfoModal && classes.smaller_font)} />
                    </div>
                }
                <div>
                    <Price price={value} currency={currency} locale={locale} currencySymbol={false} className={classNames(classes.price_ex, productInfoModal && classes.small_font)} />&nbsp;&nbsp;{ t('productdetails.pricing.ex_btw') }
                </div>
                {
                    (valueWithVat !== null && !!showValueWithVat)
                    &&
                    <div>
                        <Price price={valueWithVat} currency={currency} locale={locale} currencySymbol={false} /> { t('productdetails.pricing.inc_btw') }
                    </div>
                }
            </div>
            {/*
                dynamic.length > 0
                &&
                <div className={classes.price_volume}>
                    <span className={classes.label}>{ t('productdetails.pricing.graduated_prices') }</span>
                    <ul>
                        {
                            dynamic.map(({ key, value }) => {
                                return <li key={key.type+''+key.value}>{ t('productdetails.pricing.from_x_pices', { count: key.value }) } { value?.value && <Price price={value.value} currency={currency} locale={locale} currencySymbol={false} /> }</li>
                            })
                        }
                    </ul>
                </div>
            */}
        </div>
    );
}

export default Pricing;