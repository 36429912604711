import { useBillingAddress } from '@kega/sps-core';

import Address from '../../../../components/address/Address';

import classes from './BillingAddress.module.css';

const BillingAddress = () => {
    const { billingAddress } = useBillingAddress();

    return (
        <div className={classes.root}>
            {
                billingAddress
                &&
                <Address address={billingAddress} />
            }
        </div>
    )
}

export default BillingAddress;