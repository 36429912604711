import { useEffect, useState } from 'react';
import { useAddresses, useShippingAddress, useBillingAddress, useCart } from '@kega/sps-core';
import { useNavigate } from 'react-router-dom';

import { t } from '../../../lib/translations';

import Button from '../../../components/buttons/Button';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import Modal from '../../../components/modal/Modal';
import { AddRoundIcon } from '../../../components/icons';

import useTranslatePath from '../../../hooks/useTranslatePath';
import useUserId from "../../../hooks/useUserId";

import BillingAddress from './billing/BillingAddress';
import ShippingAddress from './shipping/ShippingAddress';
import AddAddress from './addaddress/AddAddress';
import BackToLink from '../backtolink/BackToLink';

import classes from './Addresses.module.css';

const Addresses = ({ isFromProfilePage = false }) => {
    const { fetchAddresses } = useAddresses();
    const { getPath } = useTranslatePath();
    const [ modalOpen, setModalOpen ] = useState(false);

    const [ selectedShipping, setSelectedShipping ] = useState();
    const { cartId } = useCart();
    const getUserId = useUserId();
    const userId = getUserId();

    const { loading: loadingShipping, error: errorShipping, setShippingAddress } = useShippingAddress();
    const { loading: loadingBilling, error: errorBilling, setBillingAddress, billingAddress } = useBillingAddress();

    const navigate = useNavigate();

    useEffect(() => {
        fetchAddresses();
    }, [fetchAddresses]);

    useEffect(() => {
        setBillingAddress({
            cartId: cartId,
            userId: userId
        });
    }, [cartId, userId, setBillingAddress]);

    const onShippingChange = (shippingAddressId) => {
        setSelectedShipping(shippingAddressId);
    }

    const onClick = async () => {
        const shippingAddress = await setShippingAddress({ 
            cartId: cartId, 
            address: selectedShipping,
            userId: userId
        });

        if (shippingAddress !== null && billingAddress !== null) {
            navigate(getPath('/checkout/shipment'));
        }
    }

    return (
        <div>
            { !isFromProfilePage && <BackToLink>{ t('checkout.addresses.backtocart') }</BackToLink> }

            {
                isFromProfilePage ? <h2 style={{ fontSize: 18 }}>{ t('account.directory') }</h2> : 
                    <div className={classes.header}>
                        <h1>{ t('checkout.addresses.header') }</h1>
                        <Button variant="secondary" onClick={onClick} loading={(loadingShipping || loadingBilling)}>
                            { t('checkout.addresses.btn_next') }
                        </Button>
                    </div>
            }
            
            {
                (errorShipping || errorBilling)
                &&
                <ErrorMessage>
                    { errorShipping && <span><strong>{ t('checkout.addresses.delivery_adrress_header') }</strong><br/> { errorShipping.message }</span> }
                    { errorBilling && <span><strong>{ t('checkout.addresses.payment_adrress_header') }</strong><br/> { errorBilling.message }</span> }
                </ErrorMessage>
            }

            <div className={classes.add_address}>
                <h4>{ t('checkout.addresses.delivery_adrress_header') }</h4>
                <button className={classes.add_address_button} onClick={() => setModalOpen(true)}>
                    <AddRoundIcon size={17} color="#73082F" className={classes.add_icon}  /> 
                    { t('checkout.addresses.addaddress') }
                </button>
            </div>
            <ShippingAddress selected={selectedShipping} onChange={onShippingChange} onAdd={() => setModalOpen(true)} />
            
            <h4>{ t('checkout.addresses.payment_adrress_header') }</h4>
            <BillingAddress />

            {
                !isFromProfilePage && <div className={classes.next}>
                    <Button variant="secondary" onClick={onClick} loading={(loadingShipping || loadingBilling)}>
                        { t('checkout.addresses.btn_next') }
                    </Button>
                </div>
            }

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <AddAddress closeModal={() => {setModalOpen(false)}}/>
            </Modal>

        </div>
    )
}

export default Addresses;