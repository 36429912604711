import { ProductImage, Price } from '@kega/sps-components';
import { useConfig } from '@kega/sps-core';

import { ChevronIcon } from '../../../../components/icons';

import classes from './Product.module.css';

const Product = ({ data, onClick }) => {
    const { name, Merk, Prijs:price } = data;

    const config = useConfig();

    const currency = config.get('currency');
    const locale = config.get('locale');

    const image = (data['img-256Wx256H'] ? data['img-256Wx256H'] : '/images/placeholder.png');
    const productName = data?.formattedName || data?.name;

    return (
        <div className={classes.root} onClick={onClick} >

            <div className={classes.image}>
                <ProductImage
                    src={image}
                    width={100}
                    height={100}
                    alt={name}
                />
            </div>

            <div className={classes.info_price}>
                <div className={classes.info}>
                    <span>{Merk}</span>
                    <strong>{productName}</strong>
                </div>

                <div className={classes.prices}>

                    {/*<Price price={35} currency={currency} locale={locale} className={classes.price_from} />*/}

                    {
                        price !== null
                        &&
                        <Price price={price} currency={currency} locale={locale} className={classes.price} currencySymbol={false} />
                    }

                </div>
            </div>

            <div className={classes.icon}>
                <ChevronIcon size={11} direction="right" />
            </div>

        </div>
    )
}

export default Product;