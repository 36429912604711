import { useState, useEffect, useRef } from 'react';
import { Form } from '@kega/sps-elements';
import { useChangePassword } from '@kega/sps-core';
import { useOutletContext } from "react-router-dom";

import { t } from '../../../../lib/translations';
import { Col, Row } from '../../../../components/grid';

import Input from '../../../../components/form/Input';
import Button from '../../../../components/buttons/Button';
import InfoMessage from '../../../../components/messages/InfoMessage';
import SuccessMessage from '../../../../components/messages/SuccessMessage';

import classes from './EditPassword.module.css';

const EditPassword = () => {
    const [ noMatch, setNoMatch ] = useState(false);
    const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);
    const [errors, setErrors] = useState([]);
    const { changePassword, error: resetError, loading } = useChangePassword();
    const setBreadcrumb = useOutletContext();

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Account', url_key: '/account/overview' },
            { name: t('account.edit_password.header') }
        ]);
    }, []);

    useEffect(() => {
        if (!loading) {
            if (!areArraysEqual(errors, resetError?.data?.errors || [])) {
                setErrors(resetError?.data?.errors)
            }
        }
    }, [loading]);

    const onSubmit = async ({ valid, values, resetForm }) => {
        const { oldpassword, password, password2 } = values;

        if (noMatch) { setNoMatch(false); }
        
        if (valid && password === password2) {
            try {
                const response = await changePassword({ newPassword: password, oldPassword: oldpassword });
                
                if (!response) {   // on success response is null
                    resetForm();
                    setShowSuccessMessage(true)
                }
            } catch (error) {
                // handle error
            }
        } else {
            setNoMatch(true);
        }
    }

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
      
        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();
      
        return sortedArr1.every((value, index) => value === sortedArr2[index]);
    };

    return (
        <Row>
            <Col md="12">
                <h1 className={classes.h1}>{ t('account.edit_password.header') }</h1>

                {showSuccessMessage && !resetError &&
                    <SuccessMessage className={classes.success_message}>
                        <span>{t('account.edit_password.success_message')}</span>
                    </SuccessMessage>
                }

                {!showSuccessMessage &&
                    <>
                        <InfoMessage className={classes.password_requirements}>
                            <span>{ t('account.edit_password.password_requirements_text') }</span>
                            {
                                errors?.length > 0 ? 
                                    errors?.map(({ message }, index) => {
                                        if (message === "field.password.min.one.number") {
                                            return <span key={index}>{ t('account.edit_password.password_min_digits') }<br/></span>
                                        }
                                        else if (message === "field.password.min.one.uppercase") {
                                            return <span key={index}>{ t('account.edit_password.password_min_capitals') }<br/></span>
                                        }
                                        else if (message === "field.password.min.one.special.char") {
                                            return <span key={index}>{ t('account.edit_password.password_min_spec_characters') }<br/></span>
                                        }
                                        else if (message === "field.password.min.six.characters") {
                                            return <span key={index}>{ t('account.edit_password.password_min_length') }<br/></span>
                                        } else {
                                            return <span key={index}>{ message }<br/></span>
                                        }
                                    })
                                    :
                                    <>
                                        <span>{ t('account.edit_password.password_min_digits') }<br/></span>
                                        <span>{ t('account.edit_password.password_min_capitals') }<br/></span>
                                        <span>{ t('account.edit_password.password_min_spec_characters') }<br/></span>
                                        <span>{ t('account.edit_password.password_min_length') }<br/></span>
                                    </>
                            }
                        </InfoMessage>
                        
                        <Form className={classes.form} onSubmit={onSubmit}>
                                                  
                            <Input name="oldpassword" type="password" label={ t('account.edit_password.oldpassword_label') } required rootClassName={classes.input} errorMessage={ t('form.error_not_empty') } />
                            <Input name="password" type="password" label={ t('account.edit_password.password1_label') } required rootClassName={classes.input} errorMessage={ t('form.error_not_empty') } />
                            <Input 
                                name="password2" 
                                type="password" 
                                label={ t('account.edit_password.password2_label') } 
                                required 
                                rootClassName={classes.input} 
                                error={noMatch} 
                                errorMessage={(noMatch ? t('account.edit_password.error_password_not_equal') : t('form.error_not_empty'))}
                            />

                            <Button
                                type="submit"
                                variant="primary"
                                loading={loading}
                            >
                                { t('account.edit_password.save') }
                            </Button>

                        </Form>
                    </>
                }
            </Col>
        </Row>
    )
}

export default EditPassword;

/*pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,30}$"*/