import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCartId, useCreateCart, useAddToCart, useUpdateCart, useConfig } from '@kega/sps-core';
import { ProductImage } from '@kega/sps-components';

import { t } from '../../../lib/translations';
import aa from 'search-insights'

import useTranslatePath from '../../../hooks/useTranslatePath';
import useUserId from '../../../hooks/useUserId';
import useUserToken from '../../../hooks/useUserToken';
import useTagManager from '../../../hooks/useTagManager';
import useCartItem from '../../../hooks/useCartItem';
import useItemCategories from '../../../hooks/useItemCategories';
import useUi from '../../../hooks/useUi';

import BuilderSectionLazy from '../../builder/BuilderSectionLazy';

import Pricing from '../info/pricing/Pricing';
import { default as AddToCartButton } from '../../../components/buttons/AddToCart';
import { Container } from '../../../components/grid';
import Wishlist from '../info/wishlist/Wishlist';

import classes from './ProductInfoModal.module.css';

const ProductInfoModal = ({ product, images }) => {
    const { getPath } = useTranslatePath();

    const { Merk, price = null, objectID = null, code, assortmentMinQuantity } = product;

    const productName = product?.formattedName || product?.name;
    const outOfStock = product?.stock?.stockLevelStatus === 'outOfStock';
    const stockLevel = product?.stock?.stockLevel || 9999;
    const minQuantity = assortmentMinQuantity || 1;
    const cart_item = useCartItem(product ? product.code : null);
    const leftInStock = stockLevel - (cart_item?.quantity || 0) - minQuantity;

    const { state } = useLocation();
    const { get } = useUserToken();
    const { openCart } = useUi();
    const user = get();
    const queryID = state?.queryID;
    const itemCategories = useItemCategories(product.categoryPath);
    const config = useConfig();

    const storeCode = config.get('storeCode');
    const { index: algoliaIndex } = config.get('algolia');

    const [loading, setLoading] = useState(false);

    const getCartId = useCartId();
    const getUserId = useUserId();
    const { createCart } = useCreateCart();
    const { addToCart, error } = useAddToCart();
    const { updateCart } = useUpdateCart();
    const { ecommerceEvent } = useTagManager();

    const onAddToCart = async (event) => {
        event.preventDefault();

        if (!product || outOfStock) {
            return;
        }

        const newQuantity = minQuantity;
        const updatedQuantity = cart_item ? (cart_item.quantity + newQuantity) : newQuantity;

        setLoading(true);

        const cartId = getCartId();
        const userId = getUserId();

        if (cartId === null) {
            await createCart({ userId });
        }

        if (cart_item) {
            await updateCart({
                product: {
                    code: product.code
                },
                quantity: updatedQuantity,
                entryNumber: cart_item.entryNumber,
                userId: userId
            });
        } else {
            await addToCart({
                product: {
                    code: product.code
                },
                quantity: updatedQuantity,
                userId: userId
            });
        }

        try {
            aa(queryID ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs', {
                userToken: 'user-' + user,
                eventName: queryID
                    ? 'PDP: Product Added to Cart after Search'
                    : 'PDP: Product Added to Cart',
                queryID: queryID,
                index: algoliaIndex + '-product-' + storeCode,
                objectIDs: [objectID],
            });
    
        } catch (error) {
            //
        }

        try {
            const { code, priceData } = product;

            ecommerceEvent('add_to_cart', {
                value: priceData?.value,
                currency: config.get('currency'),
                items:[{
                    item_id: code,
                    item_name: productName,
                    item_brand: Merk ?? product?.manufacturer,
                    price: priceData?.value,
                    item_category: itemCategories[0],
                    item_category2: itemCategories[1],
                    item_category3: itemCategories[2],
                    item_category4: itemCategories[3],
                    item_category5: itemCategories[4],
                }]
            });
        } catch (error) {
            console.log(error)
        }

        openCart();

        setLoading(false);

    };

    return (

        <div className={classes.root}>
            <Container xl margins g-xs={2} g-md={3}>
                <div className={classes.sticky}>
                    <div className={classes.info}>
                        <div className={classes.image}>
                            <ProductImage
                                src={images[0]?.url}
                                alt={images[0]?.alt}
                                width={200}
                                height={200}
                            />
                        </div>
                        <div>
                            <span>{Merk ?? product.manufacturer}</span>
                            <h4 className={classes.name}>{productName}</h4>
                            <BuilderSectionLazy model="usp-pdp-banner" url={getPath('/')} />
                        </div>
                    </div>

                    <div className={classes.atc}>
                        {price && <Pricing price={price} showValueWithVat={false} productInfoModal={true} />}
                        <div className={classes.actions}>
                            <AddToCartButton onClick={onAddToCart} className={classes.atc_button} loading={loading} disabled={!product || outOfStock || leftInStock < 0}>{t('productdetails.addtocart_button')}</AddToCartButton>
                            <Wishlist code={code} className={classes.wishlistbutton} />
                        </div>
                    </div>
                </div>
            </Container>

        </div>
    )
}

export default ProductInfoModal;