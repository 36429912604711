import { useRequest, useStorefront, useCustomer } from '@kega/sps-core';
import { addCustomerData } from '@kega/sps-core/src/reducers/customer/actions';
import { useDispatch } from 'react-redux';

const useFetchCustomer = () => {
    const { api: { occcustomer } } = useStorefront();
    const dispatch = useDispatch();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const customer = useCustomer();

    const getDetails = async () => {
        try {
            setRequest();

            const response = await occcustomer.getDetails();
            dispatch(addCustomerData(response));

            setSuccess();
        } catch (error) {
            console.log('[fetchCustomer]', error);
            setError(error);
            throw error;
        }
    }

    return {
        error,
        loading,
        customer,
        getDetails,
    };
}

export default useFetchCustomer;